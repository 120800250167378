import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";

import HomeLink from "./HomeLink";
// import AssetNav from "./AssetNav";
import AssetName from "./AssetName";
// import StartAndDuration from "./StartAndDuration";
import AssetPublishedAndCreatedInfo from "./AssetPublishedAndCreatedInfo";
import AssetStatisticsHeading from "./AssetStatisticsHeading";

// import ChartCard from "./Cards/ChartCard";
import ValueCard from "./Cards/ValueCard";
import ValueCard2 from "./Cards/ValueCard2";

import Button from "../Analytics/Button";
import ModalDateRangePicker from "../Analytics/ModalDateRangePicker";
import Backdrop from "../Analytics/Backdrop";

import { useMyContext } from "../../contexts/StateHolder";
import { getCategories } from "../../scripts/dataHandlers";
import { getKpiFromOpenSearch, getKpiGraphData } from "../../scripts/openSearchHandler";
import {
    formatAssetDuration,
    formatAssetPercentage,
    formatAssetRatioAsPercentage,
    formatAssetNumberValue,
    getAssetNameFromCurrentAsset,
} from "../../scripts/assetUtils";

import { getSingleAsset } from "../../scripts/dataHandlers";

// import EventOverviewTotalViewersChart from "./Charts/EventOverviewTotalViewersChart";
import GenericChart from "../Analytics/Charts/GenericChart";

import classes from "./VODAnalyticsAssetOverview.module.css";

const VODAnalyticsAssetOverview = (props) => {
    const { assetId } = useParams();

    // console.log("assetId", assetId);

    const organizationId = props.organizationId;
    const secret = props.secret;
    const createdBy = null; // make sure createdBy doesn't mess our query up
    const eventId = null;
    const groupItemId = props.groupItemId;
    // const userId = props.userId;

    // console.log("VODAnalyticsAssetOverview: orgId, secret, userId, assetId", organizationId, secret, userId, assetId);

    const { modalDateRangePickerIsOpen, setModalDateRangePickerIsOpen } = useMyContext();

    const [totalUniqueViewers, setTotalUniqueViewers] = useState(-1);
    const [totalImpressions, setTotalImpressions] = useState(-1);
    const [totalPlays, setTotalPlays] = useState(-1);
    const [totalWatchedMinutes, setTotalWatchedMinutes] = useState(-1);
    const [totalAverageViewingTime, setTotalAverageViewingTime] = useState(-1);
    const [totalAveragePercentageWatched, setTotalAveragePercentageWatched] = useState(-1);

    const [averageViewingTime, setAverageViewingTime] = useState(-1);
    const [impressions, setImpressions] = useState(-1);
    const [plays, setPlays] = useState(-1);
    // const [sumOfDailyViewers, setSumOfDailyViewers] = useState(-1);
    const [watchedMinutes, setWatchedMinutes] = useState(-1);
    const [averagePercentageWatched, setAveragePercentageWatched] = useState(-1);

    const [assetName, setAssetName] = useState("");
    const [assetStart, setAssetStart] = useState(Date.now());
    const [assetDuration, setAssetDuration] = useState(Date.now());

    const [allGroupItems, setAllGroupItems] = useState([]);

    // - voisiko date range olla aina edelliset 30 päivää?
    let e = new Date();
    let s = new Date();
    // today + 29 days = 30 days
    s.setDate(e.getDate() - 29);
    const [startDate, setStartDate] = useState(s);
    const [endDate, setEndDate] = useState(e);

    // const [arrayData, setArrayData] = useState(Date.now());

    // const [data, setData] = useState([]);

    const [asset, setAsset] = useState(null);

    // if currentAsset changes
    // - set its name
    // - set the start and end date values

    useEffect(() => {
        if (asset) {
            const name = getAssetNameFromCurrentAsset(assetId, asset);

            setAssetName(name);

            // console.log("currentAsset", currentAsset);
            // console.log("currentAsset.date", currentAsset.date);
            // console.log("currentAsset.duration", currentAsset.duration);

            if (asset.date) {
                // const t = Date.now();

                // If we set the startDate to be the start time of the event,
                // the KPI for the first day of the event will be filtered out as the
                // value is in OpenSearch with a timestamp at the beginning of the day.

                // let start = new Date(currentAsset.startTime);
                // let startDate = new Date(start.toDateString());

                // console.log("start, startDate", start, startDate);

                // setStartDate(startDate); // context
                // setEndDate(new Date(t));

                // state
                setAssetStart(asset.date);
            }

            if (asset.duration) {
                setAssetDuration(asset.duration);
            }

            // scroll to top when selecting the asset
            window.scrollTo(0, 0);
        }
    }, [assetId, asset]);

    useEffect(() => {
        const fetchAsset = async () => {
            const res = await getSingleAsset(organizationId, secret, assetId);

            if (res && res.data && res.data.status === "ok") {
                setAsset(res.data);
            }
        };
        fetchAsset();
    }, [organizationId, secret, assetId]);

    // const getAsset = async () => {
    //     try {
    //         const resEvent = await getSingleAsset(organizationId, assetId);
    //         if (resEvent.data.status === "ok") {
    //             setCurrentAsset(resEvent.data.event);
    //         }
    //     } catch (error) {
    //         console.log(error);
    //     }
    // };

    // useEffect(() => {
    //     organizationId && getAsset();
    // }, [organizationId, assetId]);

    useEffect(() => {
        const getGroupItems = async () => {
            let result = await getCategories(organizationId, "pages");
            if (typeof groupItemId === "undefined" || groupItemId === null || groupItemId === "") {
                window.location.href = window.location.href + "&groupItemId=" + result[0]?.id;
            } else {
                //console.log('RESULTS: ', result);
                setAllGroupItems(result);
            }
        };
        getGroupItems();
    }, [organizationId, groupItemId]);

    const getKpi = async (name) => {
        return getKpiFromOpenSearch(name, organizationId, groupItemId, createdBy, assetId, eventId, startDate, endDate);
    };

    // Line 162:15:  Effect callbacks are synchronous to prevent race conditions. Put the async function inside:

    // useEffect(() => {
    //   async function fetchData() {
    //     // You can await here
    //     const response = await MyAPI.getData(someId);
    //     // ...
    //   }
    //   fetchData();
    // }, [someId]); // Or [] if effect doesn't need props or state

    // Asset totally
    useEffect(() => {
        const fetchDataTw = async () => {
            const tw = await getKpi("totalUniqueViewersVOD");
            setTotalUniqueViewers(tw);
        };
        fetchDataTw();

        const fetchDataTi = async () => {
            const ti = await getKpi("totalImpressionsVOD");
            setTotalImpressions(ti);
        };
        fetchDataTi();

        const fetchDataTpAndTwm = async () => {
            const p = await getKpi("totalPlaysVOD");
            setTotalPlays(p);
            const m = await getKpi("totalWatchedMinutesVOD");
            setTotalWatchedMinutes(m);

            let tawt = 0;

            // average
            if (p > 0) {
                tawt = Number(m / p);
                setTotalAverageViewingTime(tawt);
            } else if (Number(m) === 0.0 || Number(p) === 0.0) {
                // if plays or watched minutes is 0, just set the average to 0
                tawt = Number(0.0);
                setTotalAverageViewingTime(tawt);
            }

            // console.log("assetDuration 1", assetDuration);

            if (assetDuration > 0) {
                let assetDurationMinutes = assetDuration / 60.0;

                setTotalAveragePercentageWatched((100.0 * Number(tawt)) / Number(assetDurationMinutes));
            }
        };
        fetchDataTpAndTwm();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [organizationId, assetId, startDate, endDate, assetDuration]);

    // Asset statistics
    useEffect(() => {
        const fetchDataIm = async () => {
            const im = await getKpi("impressionsVOD");
            setImpressions(im);
        };
        fetchDataIm();

        const fetchDataPlAndWm = async () => {
            const p = await getKpi("playsVOD");
            setPlays(p);
            const m = await getKpi("watchedMinutesVOD");
            setWatchedMinutes(m);

            let awt = 0;

            // average
            if (p > 0) {
                awt = Number(m / p);
                setAverageViewingTime(awt);
            } else if (Number(m) === 0.0 || Number(p) === 0.0) {
                // if plays or watched minutes is 0, just set the average to 0
                awt = Number(0.0);
                setAverageViewingTime(awt);
            }

            // console.log("assetDuration 2", assetDuration);

            if (assetDuration > 0) {
                let assetDurationMinutes = assetDuration / 60.0;

                setAveragePercentageWatched((100.0 * Number(awt)) / Number(assetDurationMinutes));
            }
        };
        fetchDataPlAndWm();

        // const fetchDataTv = async () => {
        //     const tv = await getKpi("sumOfDailyViewers");
        //     setSumOfDailyViewers(tv);
        // };
        // fetchDataTv();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [organizationId, assetId, startDate, endDate, assetDuration]);

    useEffect(() => {
        const getKpiData = async (name) => {
            const data = await getKpiGraphData(name, organizationId, groupItemId, createdBy, assetId, eventId, startDate, endDate);
            return data;
        };

        const fetchGraphData = async () => {
            const watchedMinutes = await getKpiData("watchedMinutesVOD");
            if (watchedMinutes) {
                setWatchedMinutesValues(watchedMinutes);
            }
            // const uniqueViewers = await getKpiData("uniqueViewersVOD");
            // if (uniqueViewers) {
            //     setUniqueViewersValues(uniqueViewers);
            // }
            const impressions = await getKpiData("impressionsVOD");
            if (impressions) {
                setImpressionsValues(impressions);
            }
            const plays = await getKpiData("playsVOD");
            if (plays) {
                setPlaysValues(plays);
            }
        };
        fetchGraphData();
    }, [organizationId, groupItemId, createdBy, assetId, startDate, endDate]);

    const selectDateRangeButtonHandler = () => {
        setModalDateRangePickerIsOpen(true);
    };

    const closeModalHandler = () => {
        setModalDateRangePickerIsOpen(false);
    };

    const saveModalHandler = (startDateValue, endDateValue) => {
        setStartDate(startDateValue);
        setEndDate(endDateValue);
        setModalDateRangePickerIsOpen(false);
    };

    const [watchedMinutesValues, setWatchedMinutesValues] = useState(null);
    // const [uniqueViewersValues, setUniqueViewersValues] = useState(null);
    const [impressionsValues, setImpressionsValues] = useState(null);
    const [playsValues, setPlaysValues] = useState(null);

    return (
        <div className={classes.view}>
            <HomeLink organizationId={organizationId} secret={secret} groupItemId={groupItemId} />
            <AssetName assetName={assetName} assetId={assetId}></AssetName>
            <div className={classes.groupItemTitle}>Video page: {allGroupItems.find((groupItem) => Number(groupItem.id) === Number(groupItemId))?.title}</div>
            <AssetPublishedAndCreatedInfo asset={asset} assetStart={assetStart} assetDuration={assetDuration} />

            {/* <AssetNav assetId={assetId} /> */}

            <h2>Asset totally</h2>

            {/* <div className={classes.overviewWrapper14}>
                <ValueCard value={formatAssetNumberValue(totalPlays)} title={"Total plays"} />
                <ValueCard value={formatAssetDuration(totalWatchedMinutes)} title={"Total watched time"} />
                <ValueCard value={formatAssetDuration(totalAverageViewingTime)} title={"Average viewing time"} />
                <ValueCard value={formatAssetPercentage(totalAveragePercentageWatched)} title={"Average percentage watched"} />
            </div> */}

            {/* COMMENTED OUT UNTIL UNIQUE VIEWERS AND TOTAL IMPRESSIONS ARE FIXED */}
            <div className={classes.overviewWrapper14}>
                <ValueCard value={formatAssetNumberValue(totalUniqueViewers)} title={"Unique viewers"} />
                <ValueCard value={formatAssetNumberValue(totalImpressions)} title={"Total impressions"} />
                <ValueCard value={formatAssetNumberValue(totalPlays)} title={"Total plays"} />
                <ValueCard value={formatAssetRatioAsPercentage(totalPlays, totalImpressions)} title={"Ratio of plays to impressions"} />
            </div>

            <div className={classes.overviewWrapper2}>
                <ValueCard2 value={formatAssetDuration(totalWatchedMinutes)} title={"Total watched time"} />
                <ValueCard2 value={formatAssetDuration(totalAverageViewingTime)} title={"Average viewing time"} />
                <ValueCard2 value={formatAssetPercentage(totalAveragePercentageWatched)} title={"Average percentage watched"} />
            </div>

            <div className={classes.actions}>
                <Button color="#0a7aad" text="Select Date Range" onClick={selectDateRangeButtonHandler} />
            </div>

            <AssetStatisticsHeading startDate={startDate} endDate={endDate} />

            <div className={classes.overviewWrapper15}>
                {/* <ValueCard value={sumOfDailyViewers} title={"Sum of daily viewers"} /> */}

                <ValueCard value={formatAssetNumberValue(impressions)} title={"Impressions"} />
                <ValueCard value={formatAssetNumberValue(plays)} title={"Plays"} />
                <ValueCard value={formatAssetDuration(watchedMinutes)} title={"Watched time"} />
                <ValueCard value={formatAssetDuration(averageViewingTime)} title={"Avg. viewing time"} />
                <ValueCard value={formatAssetPercentage(averagePercentageWatched)} title={"Avg. percentage watched"} />
            </div>

            {/* <div className={classes.overviewWrapper2}>
                <ChartCard
                    type={"PieChart"}
                    title={"Impressions VOD and ALL"}
                    title1={"VOD"}
                    title2={"ALL"}
                    value1={formatAssetNumberValue(0)}
                    value2={formatAssetNumberValue(impressions)}
                />
                <ChartCard
                    type={"PieChart"}
                    title={"Plays VOD and ALL"}
                    title1={"VOD"}
                    title2={"ALL"}
                    value1={formatAssetNumberValue(0)}
                    value2={formatAssetNumberValue(plays)}
                />
                <ChartCard
                    type={"PieChart"}
                    title={"Watched minutes VOD and ALL"}
                    title1={"VOD"}
                    title2={"ALL"}
                    value1={formatAssetNumberValue(0)}
                    value2={formatAssetNumberValue(watchedMinutes)}
                />
                <ChartCard
                    type={"ColumnChart"}
                    title={"Average viewing times (min)"}
                    title1={"VOD"}
                    title2={"ALL"}
                    value1={Number(0)}
                    value2={Number(averageViewingTime)}
                />
            </div> */}

            {modalDateRangePickerIsOpen && (
                <ModalDateRangePicker startDate={startDate} endDate={endDate} onOk={saveModalHandler} onCancel={closeModalHandler} />
            )}
            {modalDateRangePickerIsOpen && <Backdrop onCancel={closeModalHandler} />}

            {/* <h2>Total Viewers</h2>

            <div className={classes.overviewWrapper3}>
                <div className={classes.resultItem3}>
                    <EventOverviewTotalViewersChart
                        organizationId={organizationId}
                        assetId={assetId}
                        startDate={startDate}
                        endDate={endDate}
                    />
                </div>
            </div> */}

            <h2>Plays</h2>

            <div className={classes.overviewWrapper3}>
                <div className={classes.resultItem3}>
                    <GenericChart
                        chartType={"playsVOD"}
                        data={[
                            {
                                name: "impressionsVOD",
                                values: impressionsValues,
                            },
                            {
                                name: "playsVOD",
                                values: playsValues,
                            },
                            {
                                name: "watchedMinutesVOD",
                                values: watchedMinutesValues,
                            },
                        ]}
                        organizationId={organizationId}
                        assetId={assetId}
                        startDate={startDate}
                        endDate={endDate}
                    />
                </div>
            </div>

            <h2>Impressions</h2>

            <div className={classes.overviewWrapper3}>
                <div className={classes.resultItem3}>
                    <GenericChart
                        chartType={"impressionsVOD"}
                        data={[
                            {
                                name: "impressionsVOD",
                                values: impressionsValues,
                            },
                            {
                                name: "playsVOD",
                                values: playsValues,
                            },
                            {
                                name: "watchedMinutesVOD",
                                values: watchedMinutesValues,
                            },
                        ]}
                        organizationId={organizationId}
                        assetId={assetId}
                        startDate={startDate}
                        endDate={endDate}
                    />
                </div>
            </div>

            <h2>Watched minutes</h2>

            <div className={classes.overviewWrapper3}>
                <div className={classes.resultItem3}>
                    <GenericChart
                        chartType={"watchedMinutesVOD"}
                        data={[
                            {
                                name: "impressionsVOD",
                                values: impressionsValues,
                            },
                            {
                                name: "playsVOD",
                                values: playsValues,
                            },
                            {
                                name: "watchedMinutesVOD",
                                values: watchedMinutesValues,
                            },
                        ]}
                        organizationId={organizationId}
                        assetId={assetId}
                        startDate={startDate}
                        endDate={endDate}
                    />
                </div>
            </div>
        </div>
    );
};

export default VODAnalyticsAssetOverview;
