export const getKpiName = (kpiNickName, minutePrecision, organizationId, groupItemId, createdBy, assetId, eventId) => {
    let firstPart = getFirstPart(kpiNickName);
    let secondPart = getSecondPart(organizationId, groupItemId, createdBy, assetId, eventId);
    let thirdPart = getThirdPart(kpiNickName, minutePrecision);

    let kpiName = firstPart + "-" + secondPart + "-" + thirdPart;

    return kpiName;
};

// case "totalDataTransfer":
//     kpiName = "cdn-all-data-transfer-organization-total";
//     break;

// case "totalDataTransferVOD":
//     kpiName = "cdn-vod-data-transfer-organization-total";
//     break;

// case "totalDataTransferLIVE":
//     kpiName = "cdn-live-data-transfer-organization-total";
//     break;

export const getFirstPart = (kpiNickName) => {
    let firstPart = "";
    switch (kpiNickName) {
        // TOTALS
        case "totalUniqueViewers":
            firstPart = "all-unique";
            break;

        case "totalWatchedMinutes":
            firstPart = "all-watched-minutes";
            break;

        case "totalImpressions":
            firstPart = "all-player-loads";
            break;

        case "totalPlays":
            firstPart = "all-player-starts";
            break;

        case "totalUniqueViewersLIVE":
            firstPart = "live-unique";
            break;

        case "totalWatchedMinutesLIVE":
            firstPart = "live-watched-minutes";
            break;

        case "totalImpressionsLIVE":
            firstPart = "live-player-loads";
            break;

        case "totalPlaysLIVE":
            firstPart = "live-player-starts";
            break;

        case "totalUniqueViewersVOD":
            firstPart = "vod-unique";
            break;

        case "totalWatchedMinutesVOD":
            firstPart = "vod-watched-minutes";
            break;

        case "totalImpressionsVOD":
            firstPart = "vod-player-loads";
            break;

        case "totalPlaysVOD":
            firstPart = "vod-player-starts";
            break;

        // NOT TOTALS
        case "impressions":
            firstPart = "all-player-loads";
            break;

        case "impressionsVOD":
            firstPart = "vod-player-loads";
            break;

        case "impressionsLIVE":
            firstPart = "live-player-loads";
            break;

        case "plays":
            firstPart = "all-player-starts";
            break;

        case "playsVOD":
            firstPart = "vod-player-starts";
            break;

        case "playsLIVE":
            firstPart = "live-player-starts";
            break;

        case "watchedMinutes":
            firstPart = "all-watched-minutes";
            break;

        case "watchedMinutesVOD":
            firstPart = "vod-watched-minutes";
            break;

        case "watchedMinutesLIVE":
            firstPart = "live-watched-minutes";
            break;

        case "uniqueViewers":
            firstPart = "all-unique-event-viewer";
            break;

        case "uniqueViewersVOD":
            firstPart = "vod-unique-event-viewer";
            break;

        case "uniqueViewersLIVE":
            firstPart = "live-unique-event-viewer";
            break;

        default:
            firstPart = "firstPartTODO";
            break;
    }

    return firstPart;
};

export const getSecondPart = (organizationId, groupItemId, createdBy, assetId, eventId) => {
    if (assetId) {
        return "asset";
    } else if (eventId) {
        return "event";
    } else if (createdBy) {
        return "creator";
    } else if (groupItemId) {
        return "groupitem";
    } else if (organizationId) {
        return "organization";
    }
    return "secondPartTODO";
};

export const getThirdPart = (kpiNickName, minutePrecision) => {
    if (kpiNickName.startsWith("totalUnique")) {
        return "viewer-total";
    }
    if (kpiNickName.startsWith("total")) {
        return "total";
    }
    if (minutePrecision) {
        return "1-min";
    }
    return "1-day";
};
